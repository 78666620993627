import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import Button from 'shopper/components/Button';
import Icon from 'shopper/components/Icon';

import { sendEvent } from 'lib/gtag';

import { useUser } from 'providers/UserProvider';

const FiltersDropdown = dynamic(() => import('./Dropdown'), { ssr: false });

const OfferCardFiltersDropdown = ({
  buttonVisibilityClassName,
  categoryId,
  categoryName = null,
  className,
  gaEventCategory,
  isInternationalOffer = false,
  isParentFocused = false,
  subcategoryId = null,
  subcategoryName = null,
  ...rest
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const buttonRef = useRef(null);
  const { runCallbackIfLoggedIn } = useUser();

  const onToggleDropdownClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    sendEvent({
      action: 'show_hide_categories_card',
      category: gaEventCategory,
    });

    runCallbackIfLoggedIn(() => {
      setDropdownOpen((prevState) => !prevState);
    });
  };

  return (
    <div className={twMerge('relative flex h-fit', className)} {...rest}>
      <Button
        ref={buttonRef}
        aria-label="Abrir opções da oferta"
        className={twMerge(
          isDropdownOpen && !isParentFocused
            ? 'visible'
            : buttonVisibilityClassName
        )}
        icon={<Icon name="more-alt" />}
        size="size4"
        type="secondary-ghost"
        onClick={onToggleDropdownClick}
      />
      {isDropdownOpen && (
        <FiltersDropdown
          categoryId={categoryId}
          categoryName={categoryName}
          gaEventCategory={gaEventCategory}
          isInternationalOffer={isInternationalOffer}
          isOpen={isDropdownOpen}
          openerRef={buttonRef}
          subcategoryId={subcategoryId}
          subcategoryName={subcategoryName}
          onClose={() => setDropdownOpen(false)}
        />
      )}
    </div>
  );
};

OfferCardFiltersDropdown.propTypes = {
  categoryId: PropTypes.number.isRequired,
  categoryName: PropTypes.string,
  gaEventCategory: PropTypes.string.isRequired,
  isInternationalOffer: PropTypes.bool,
  isParentFocused: PropTypes.bool,
  subcategoryId: PropTypes.number,
  subcategoryName: PropTypes.string,
};

export default OfferCardFiltersDropdown;
